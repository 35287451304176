var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"pa-4 mb-2",attrs:{"elevation":"1"}},[_c('div',{class:['filter-item', { 'is-active': _vm.isExpanded }]},[_c('div',{staticClass:"filter-item__title",on:{"click":function($event){_vm.isExpanded = !_vm.isExpanded}}},[_c('p',{staticClass:"title"},[_vm._v("\n        "+_vm._s(_vm.$t('App#Filters#PriceRange#Title Price Range'))+"\n      ")])]),_vm._v(" "),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded),expression:"isExpanded"}],staticClass:"filter-item__content price-range"},[_c('div',{staticClass:"price-result"},[_c('span',{staticClass:"price-result__min"},[_vm._v(_vm._s(_vm.priceResult.min))]),_vm._v(" "),_c('span',{staticClass:"price-result__max"},[_vm._v(_vm._s(_vm.priceResult.max))])]),_vm._v(" "),_c('div',{staticClass:"price-message"},[_c('span',[_vm._v(_vm._s(_vm.$t('App#Filters#PriceRange Average nightly rate')))])]),_vm._v(" "),_c('div',{staticClass:"price-histogram"},_vm._l((_vm.histogramOptions.columns),function(i){return _c('div',{key:i,class:[
              'price-histogram__column',
              { 'is-active': _vm.isColumnActive(i) }
            ],style:({
              height: _vm.histogramColumns[`col-${i}`]
                ? _vm.histogramColumns[`col-${i}`].perc + 'px'
                : 0
            }),on:{"click":function($event){return _vm.setColumnActive(i)}}})}),0),_vm._v(" "),_c('vue-slider',_vm._b({ref:"priceRangeSlider",model:{value:(_vm.sliderValue),callback:function ($$v) {_vm.sliderValue=$$v},expression:"sliderValue"}},'vue-slider',_vm.options,false))],1)])],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }